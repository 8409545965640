import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons'

//Utils
import { getFont } from '../../../utils/utils'

// Models
import { TierModel } from '../../../models'

// GraphQL
import { getGraphChapters, getGraphTiers } from '../../../graphQL'

// Context
import UserContext from '../../../context/User/User.context'
import LocalizationContext from '../../../context/Localization/Localization.context'

// Components
import DilanButton from '../../atoms/DilanButton'
import EmblemCard from '../../molecules/EmblemCard'

// Constants
import { READER_PAGE, USER_TOKEN } from '../../../config/constants/localStorage'

// Styles
import {
  editBut,
  container,
  imgCanvas,
  imgAvatar,
  arrowBack,
  infoPanel,
  marginTier,
  textMargin,
  currentText,
  imgSelector,
  selectorImg,
  selectorExtra,
  selectorPanel,
  selectorTitle,
  floatingButton,
  pbCorrectionBut,
} from './styles.module.scss'

// Services
import { updateImage } from '../../../services/auth'
import { commentsByUser } from '../../../services/user'

interface Props {
  localization: {
    hello_title: string
    user_since: string
    comment_num: string
    current_chapter: string
    support_us: string
    keep_reading: string
    select_image: string
  }
}

const UserImage: React.FC<Props> = ({ localization }) => {
  const { user, updateUser } = useContext(UserContext)
  const { language } = useContext(LocalizationContext)

  const [comments, setComments] = useState(0)
  const [selector, setSelector] = useState(false)
  const [tier, setTier] = useState<null | TierModel>(null)
  const [chapter, setChapter] = useState('0')

  const allTiers = getGraphTiers()
  const allChapter = getGraphChapters()

  const getCommentsByUser = async () => {
    const token = localStorage.getItem(USER_TOKEN)
    if (token) {
      const numComments = await commentsByUser(token)
      setComments(numComments)
    }
  }

  useEffect(() => {
    getCommentsByUser()
    if (user) {
      const userChapter = allChapter.find(
        (ch) => String(ch.id) === user.lastChapter
      )
      if (userChapter) {
        setChapter(`${userChapter.series.prefix}${userChapter.number}`)
      }
    }
  }, [user])

  useEffect(() => {
    if (user) {
      const fullTier = allTiers.find((ti) => String(ti.id) === user.tier)
      if (fullTier) {
        setTier(fullTier)
      } else {
        setTier(null)
      }
    }
  }, [user, user?.tier])

  return (
    <>
      <div className={`${container} relative`}>
        <div className={imgCanvas}>
          {user && (
            <img
              src={`https://dilancovak.com/.img/users/User${user?.image}.jpg`}
              className={imgAvatar}
            />
          )}
          <div
            className={editBut}
            onClick={() => {
              setSelector(true)
            }}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </div>
        </div>
        <div className={infoPanel}>
          <h2 className={getFont('font-patua')}>
            {`${localization.hello_title} ${user?.username}`}
          </h2>
          <div className={`${getFont('font-lato')} ${textMargin}`}>
            {`${localization.user_since} ${user?.since
              ?.split('T')[0]
              .replace(/-/g, '/')}`}
          </div>
          {tier ? (
            <div className="only-mobile-tb">
              <EmblemCard
                className={marginTier}
                logoPath={tier.emblem}
                name={tier.name[language]}
                onClick={() => {
                  navigate(`/support?tier=${tier.id}`)
                }}
              />
            </div>
          ) : (
            <DilanButton
              type={'primary'}
              className={`${marginTier} ${getFont(
                'font-lato',
                pbCorrectionBut
              )}`}
              handleClick={() => {
                navigate('/support')
              }}
            >
              {localization.support_us}
            </DilanButton>
          )}
          <div className={getFont('font-lato')}>
            {`${localization.comment_num} ${comments}`}
          </div>
          <div
            className={`${currentText} ${getFont('font-lato')} only-mobile-tb`}
          >{`${localization.current_chapter}: ${chapter}`}</div>
        </div>
        <div className={`${floatingButton} only-mobile-tb`}>
          <DilanButton
            type={'primary'}
            className={getFont('font-lato', pbCorrectionBut)}
            handleClick={() => {
              localStorage.setItem(READER_PAGE, String(user?.lastPage))
              navigate(`/reader/${chapter}`)
            }}
          >
            {localization.keep_reading}
          </DilanButton>
        </div>
      </div>
      <div className={`${imgSelector} ${selector ? '' : 'hidden'}`}>
        <h2 className={`${selectorTitle} ${getFont('font-patua')}`}>
          <FontAwesomeIcon
            className={arrowBack}
            icon={faTimes}
            onClick={() => {
              setSelector(false)
            }}
          />
          {localization.select_image}
        </h2>
        <div className={selectorPanel}>
          {user?.images?.map((img) => (
            <img
              key={`selectro-img-${img}`}
              className={selectorImg}
              src={`https://dilancovak.com/.img/users/User${img}.jpg`}
              onClick={async () => {
                try {
                  await updateImage(
                    img,
                    localStorage.getItem(USER_TOKEN) as string
                  )
                  updateUser({ ...user, image: img })
                  setSelector(false)
                } catch (error) {
                  console.error(error)
                }
              }}
            />
          ))}
        </div>
        <div className={selectorExtra} />
      </div>
    </>
  )
}

export default UserImage
