import React from 'react'

// Components
import Account from '../components/templates/Account'
import PageTemplate from '../components/templates/PageTemplate'

const AccountPage = () => {
    return (
        <PageTemplate>
            <Account />
        </PageTemplate>
    )
}

export default AccountPage