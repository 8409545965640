import React from 'react'

// Utils
import { getFont } from '../../../utils/utils'

// Styles
import {
  fixed,
  expanded,
  pbCorrection,
  emblem,
  emblemIcon,
  emblemText,
  emblemOverlay,
} from './styles.module.scss'

interface Props {
  logoPath: string
  name: string
  onClick?: () => void
  className?: string
  expand?: boolean
}

const EmblemCard: React.FC<Props> = ({
  logoPath,
  name,
  onClick = () => null,
  className = '',
  expand = false,
}) => {
  return (
    <div
      className={`${emblem} ${className} ${expand ? expanded : fixed}`}
      onClick={onClick}
    >
      <div className={`${emblemText} ${getFont('font-lato', pbCorrection)}`}>
        {name}
      </div>
      <img
        className={emblemIcon}
        src={`https://dilancovak.com/.img/emblems/${logoPath}`}
      />
      <div className={emblemOverlay} />
    </div>
  )
}

export default EmblemCard
