// extracted by mini-css-extract-plugin
export var container = "styles-module--container--Iw+40";
export var imgCanvas = "styles-module--img-canvas--c0CMn";
export var imgAvatar = "styles-module--img-avatar--uFXuV";
export var imgSelector = "styles-module--img-selector--bnBa7";
export var editBut = "styles-module--edit-but--AL4-G";
export var infoPanel = "styles-module--info-panel--NqSjz";
export var textMargin = "styles-module--text-margin--X9ory";
export var marginTier = "styles-module--margin-tier--7WiH1";
export var pbCorrection = "styles-module--pb-correction--9rH-4";
export var pbCorrectionBut = "styles-module--pb-correction-but--9QxWX";
export var currentText = "styles-module--current-text--W98AK";
export var floatingButton = "styles-module--floating-button--UjLNj";
export var selectorImg = "styles-module--selector-img--X9KCJ";
export var selectorPanel = "styles-module--selector-panel--X6lxD";
export var selectorTitle = "styles-module--selector-title--B9E8C";
export var selectorExtra = "styles-module--selector-extra--aVe0l";
export var arrowBack = "styles-module--arrow-back--Jd1oZ";