import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'

// Components
import DilanButton from '../../atoms/DilanButton'
import EmblemCard from '../../molecules/EmblemCard'

// Utils
import { getFont } from '../../../utils/utils'

// Models
import { TierModel } from '../../../models'

// graphQL
import { getGraphTiers } from '../../../graphQL'

// Context
import UserContext from '../../../context/User/User.context'
import LocalizationContext from '../../../context/Localization/Localization.context'

// Styles
import {
  container,
  emptyTier,
  titleStyle,
  rewardText,
  rewardCard,
  rewardTitle,
  rewardPanel,
  pbCorrection,
  pbCorrectionBut,
} from './styles.module.scss'

interface Props {
  title: string
  support_us: string
}

const PatreonArea: React.FC<Props> = ({ title, support_us }) => {
  const { user } = useContext(UserContext)
  const { language } = useContext(LocalizationContext)

  const [tier, setTier] = useState<null | TierModel>(null)

  const allTiers = getGraphTiers()

  useEffect(() => {
    if (user) {
      const fullTier = allTiers.find((ti) => String(ti.id) === user.tier)
      if (fullTier) {
        setTier(fullTier)
      } else {
        setTier(null)
      }
    }
  }, [user, user?.tier])

  return (
    <div className={`${container} only-desktop-flex`}>
      <h2 className={`${titleStyle} ${getFont('font-patua')}`}>{title}</h2>
      {tier ? (
        <>
          <EmblemCard
            logoPath={tier.emblem}
            name={tier.name[language]}
            onClick={() => {
              navigate(`/support?tier=${tier.id}`)
            }}
          />
          <div className={rewardPanel}>
            {tier.rewards.map((reward, index) => (
              <div
                key={`reward_${reward[language] ? reward[language] : index}`}
                className={rewardCard}
              >
                <div
                  className={`${rewardTitle} ${getFont(
                    'font-lato',
                    pbCorrection
                  )}`}
                >
                  {index + 1}
                </div>
                <div
                  className={`${rewardText} ${getFont(
                    'font-lato',
                    pbCorrection
                  )}`}
                >
                  {reward[language]}
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className={emptyTier} />
          <DilanButton
            type={'primary'}
            className={`${getFont('font-lato', pbCorrectionBut)}`}
            handleClick={() => {
              navigate('/support')
            }}
          >
            {support_us}
          </DilanButton>
        </>
      )}
    </div>
  )
}

export default PatreonArea
