import React, { useEffect, useRef, useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

//Utils
import { getFont } from '../../../utils/utils'

// Styles
import {
  legend,
  legendUp,
  errorIcon,
  legendDown,
  fieldStyle,
  inputStyle,
  errorStyle,
  errorBorder,
  wrapperStyle,
} from './styles.module.scss'

interface Props {
  label: string
  type: 'text' | 'password'
  error?: string
  register: UseFormRegisterReturn
  autocomplete?: string
}

const TextField: React.FC<Props> = ({
  label,
  error,
  type,
  register: { ref, onBlur, onChange, name },
  autocomplete,
}) => {
  const field = useRef<HTMLInputElement | null>(null)

  const [upper, setUpper] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      const target = document.getElementById(
        `input_${name}`
      ) as HTMLInputElement
      field.current?.setAttribute('style', '')
      if (target.value && target.value.length > 0) {
        setUpper(true)
      }
    }, 100)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className={wrapperStyle}>
      <fieldset className={`${fieldStyle} ${error ? errorBorder : ''}`}>
        <input
          id={`input_${name}`}
          name={name}
          className={inputStyle}
          type={type}
          onFocus={() => {
            setUpper(true)
          }}
          onBlur={(event) => {
            onBlur(event)
            if (
              typeof event.target.value === 'undefined' ||
              event.target.value === null ||
              event.target.value === ''
            ) {
              setUpper(false)
            }
          }}
          onChange={(event) => {
            onChange(event)
          }}
          ref={(reference) => {
            field.current = reference
            ref(reference)
          }}
          autoComplete={autocomplete}
        />
        <legend
          className={`${legend} ${upper ? legendUp : legendDown} ${getFont(
            'font-lato'
          )}`}
        >
          {label}
        </legend>
        {error && (
          <FontAwesomeIcon icon={faExclamationCircle} className={errorIcon} />
        )}
      </fieldset>
      <div className={`${errorStyle} ${getFont('font-lato')}`}>
        {error ? error : ''}
      </div>
    </div>
  )
}

export default TextField
