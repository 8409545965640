import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'

// Components
import DilanButton from '../../atoms/DilanButton'

// Utils
import { getFont } from '../../../utils/utils'

// graphQL
import { getGraphChapters } from '../../../graphQL'

// Context
import UserContext from '../../../context/User/User.context'

// Constants
import { READER_PAGE } from '../../../config/constants/localStorage'

// Styles
import {
  container,
  infoPanel,
  imgCanvas,
  imgChapter,
  weightCorrection,
} from './styles.module.scss'

interface Props {
  localization: {
    keep_reading: string
    current_chapter: string
  }
}

const ReadingArea: React.FC<Props> = ({ localization }) => {
  const { user } = useContext(UserContext)
  const [chapter, setChapter] = useState('0')

  const allChapter = getGraphChapters()

  useEffect(() => {
    if (user) {
      const userChapter = allChapter.find(
        (ch) => String(ch.id) === user.lastChapter
      )
      if (userChapter) {
        setChapter(`${userChapter.series.prefix}${userChapter.number}`)
      }
    }
  }, [user])

  return (
    <div className={`${container} only-desktop-flex`}>
      <div className={imgCanvas}>
        {user && (
          <img
            src={`https://dilancovak.com/.img/covers/CoverCap${chapter}.jpg`}
            className={imgChapter}
          />
        )}
      </div>
      <div className={infoPanel}>
        <div className={getFont('font-lato')}>
          {localization.current_chapter}
        </div>
        <h2 className={getFont('font-lato')}>{chapter}</h2>
        <DilanButton
          type="primary"
          handleClick={() => {
            localStorage.setItem(READER_PAGE, String(user?.lastPage))
            navigate(`/reader/${chapter}`)
          }}
          className={getFont('font-lato', weightCorrection)}
        >
          {localization.keep_reading}
        </DilanButton>
      </div>
    </div>
  )
}

export default ReadingArea
