import Axios from 'axios'

const { ENDPOINT } = process.env

export const commentsByUser = (token: string): Promise<number> =>
  Axios.get(`${ENDPOINT}/user/comments`, { headers: { token } }).then(
    (res) => res.data.result
  )

export const readByUser = (token: string): Promise<number[]> =>
  Axios.get(`${ENDPOINT}/user/chapters`, { headers: { token } }).then(
    (res) => res.data.result
  )
