import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

// Components
import TextField from '../../atoms/TextField'
import DilanButton from '../../atoms/DilanButton'

//Utils
import { getFont } from '../../../utils/utils'

// Styles
import {
  formStyle,
  titleStyle,
  actionButtons,
  changeModal,
  changeModalIcon,
  changeModalOverlay,
} from './styles.module.scss'

// Services
import { changePassword } from '../../../services/auth'

// Constant
import { USER_TOKEN } from '../../../config/constants/localStorage'

interface Props {
  localization: {
    title: string
    password: string
    passwordNew: string
    passwordConfirm: string
    change: string
    required: string
    password_format: string
    min_password: string
    confirm_invalid: string
    success_change: string
    invalid_pass: string
    unexpected_error: string
  }
}

interface FormData {
  username: string
  password: string
  newPassword: string
  confirmPassword: string
}

const ChangePasswordFrom: React.FC<Props> = ({ localization }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [modal, setModal] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const {
    register,
    setValue,
    setError,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({ mode: 'onChange' })

  const commonValidationObj = { required: localization.required }

  const passValidationObj = {
    ...commonValidationObj,
    pattern: {
      value: /^[A-Za-z0-9_#$%.,@-]*$/,
      message: localization.password_format,
    },
  }

  const newPassValidationObj = {
    ...commonValidationObj,
    pattern: {
      value: /^[A-Za-z0-9_#$%.,@-]*$/,
      message: localization.password_format,
    },
    minLength: {
      value: 6,
      message: localization.min_password,
    },
  }

  const confirmValidationObj = {
    ...commonValidationObj,
    validate: {
      confirm: (v) =>
        v === getValues('newPassword') || localization.confirm_invalid,
    },
  }

  const handleForm: SubmitHandler<FormData> = async (data) => {
    setDisabled(true)
    try {
      const token = executeRecaptcha
        ? await executeRecaptcha('Change_Password')
        : ''
      await changePassword(localStorage.getItem(USER_TOKEN) as string, {
        ...data,
        token,
      })
      setModal(true)
      setValue('password', '')
      setValue('newPassword', '')
      setValue('confirmPassword', '')
    } catch (error) {
      if (error.response.data.message === 'Invalid password') {
        setError('password', { message: localization.invalid_pass })
      } else {
        setError('password', { message: localization.unexpected_error })
      }
    }
    setDisabled(false)
  }

  return (
    <>
      {modal && (
        <div
          className={changeModalOverlay}
          onClick={() => {
            setModal(false)
          }}
        >
          <div className={changeModal}>
            <div className={getFont('font-lato')}>
              {localization.success_change}
            </div>
            <FontAwesomeIcon className={changeModalIcon} icon={faCheckCircle} />
            <DilanButton
              className={getFont('font-lato')}
              type={'primary'}
              handleClick={() => {
                setModal(false)
              }}
            >
              OK
            </DilanButton>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(handleForm)} className={formStyle}>
        <h2 className={`${titleStyle} ${getFont('font-patua')}`}>
          {localization.title}
        </h2>
        <TextField
          type="password"
          label={localization.password}
          error={errors.password?.message}
          autocomplete="current-password"
          register={register('password', passValidationObj)}
        />
        <div style={{ margin: '8px' }} />
        <TextField
          type="password"
          label={localization.passwordNew}
          error={errors.newPassword?.message}
          autocomplete="new-password"
          register={register('newPassword', newPassValidationObj)}
        />
        <div style={{ margin: '8px' }} />
        <TextField
          type="password"
          label={localization.passwordConfirm}
          error={errors.confirmPassword?.message}
          autocomplete="new-password"
          register={register('confirmPassword', confirmValidationObj)}
        />
        <div style={{ margin: '12px' }} />
        <DilanButton
          type="primary"
          className={`${actionButtons} ${getFont('font-patua')}`}
          handleClick={() => {}}
          disabled={disabled}
        >
          {localization.change}
        </DilanButton>
      </form>
    </>
  )
}

export default ChangePasswordFrom
